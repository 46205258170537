<template>
    <Listbox v-model="values" multiple v-slot="{open}">
        <ListboxLabel class="sr-only">
            {{ placeholder }}
        </ListboxLabel>

        <div class="relative">
              <ListboxButton
                    :class="[
                        `inline-block w-full rounded-md group cursor-pointer`,
                        `relative w-full rounded-md bg-white py-2 pl-2 pr-10 text-left sm:text-sm sm:leading-5`,
                        `transition duration-300 ease-in-out ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-400 hover:ring-gray-400`,
                        // `border border-gray-200`,
                        // `ring-1 ring-gray-900 focus:ring-[3px] focus:ring-offset-1 focus:ring-offset-yellow-700 focus:ring-yellow-300`,
                    ]"
                >
                <span class="text-gray-600">
                    {{ placeholder }}
                </span>
                  <div class="absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon :class="[
                          `h-5 w-5 text-gray-400 transition-all duration-300 ease-in-out`,
                          open ? `rotate-180` : ``,
                      ]" />
                  </div>
              </ListboxButton>

            <div class="absolute mt-1 w-full rounded-md bg-white z-50">
                <transition
                    enter-active-class="transition duration-100 ease-out"
                    enter-from-class="transform scale-95 opacity-0"
                    enter-to-class="transform scale-100 opacity-100"
                    leave-active-class="transition duration-75 ease-out"
                    leave-from-class="transform scale-100 opacity-100"
                    leave-to-class="transform scale-95 opacity-0"
                >
                    <ListboxOptions
                        class="max-h-60 overflow-auto rounded-md py-1 text-base leading-6 focus:outline-none sm:text-sm sm:leading-5 border border-gray-200 no-scrollbar"
                    >
                        <ListboxOption
                            v-for="option in options.filter((_option) => _option.name.toLowerCase().includes(query.toLowerCase()))"
                            :key="`filter_${option.id}`"
                            :value="option.id"
                            as="template"
                            v-slot="{ active, selected }"
                        >
                            <li
                                class="relative cursor-pointer select-none py-2 pl-3 pr-9 focus:outline-none transition-colors duration-300 ease-in-out"
                                :class="active ? 'bg-yellow-100 text-yellow-900' : 'text-gray-900'"
                            >
                          <span
                              class="block truncate"
                              :class="{ 'font-semibold': selected, 'font-normal': !selected }"
                          >
                            {{ option.name }}
                          </span>
                                <span
                                    v-if="selected"
                                    class="absolute inset-y-0 right-0 flex items-center pr-4"
                                    :class="{
                                        'text-yellow-900': active,
                                        'text-yellow-900': !active
                                    }"
                                >
                            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                              />
                            </svg>
                          </span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </div>
    </Listbox>
</template>

<script setup>
import {ref} from 'vue';
import {
    Listbox, ListboxLabel, ListboxButton, ListboxOptions, ListboxOption,
} from '@headlessui/vue';
import {ChevronDownIcon} from "@heroicons/vue/16/solid";

defineEmits(['change'])

const values = defineModel();

const props = defineProps({
    options: {
        type: Array,
        required: true,
    },
    placeholder: {
        type: String,
        required: false,
        default: '🔍 Search...',
    }
});

let query = ref('');
</script>
