<template>
    <App>
        <AppHead
            title="Home"
            :url="route('index')"
        />
        <div class="border-b border-gray-200 pb-4">
            <Disclosure as="div" class="block sm:hidden" v-slot="{open}">
                <DisclosureButton class="w-full">
                    <div class="flex sm:hidden px-6 mb-2 justify-between items-center">
                        <div>
                            <Heading>
                                Tags
                            </Heading>
                        </div>
                        <ChevronDownIcon :class="[`w-8 h-8 text-gray-900 transition-all duration-300 ease-in-out`, open ? `rotate-180` : ``,]" />
                    </div>
                </DisclosureButton>
                <transition
                    enter-active-class="transition duration-100 ease-out"
                    enter-from-class="transform scale-95 opacity-0"
                    enter-to-class="transform scale-100 opacity-100"
                    leave-active-class="transition duration-75 ease-out"
                    leave-from-class="transform scale-100 opacity-100"
                    leave-to-class="transform scale-95 opacity-0"
                >
                    <DisclosurePanel class="text-gray-500">
                        <div class="flex flex-col gap-x-1.5 gap-y-2 px-6 mb-4">
                            <div>
                                <MultiSelect :options="types" v-model="form.types" placeholder="🔍 Type" @update:modelValue="filter"/>
                            </div>
                            <div>
                                <MultiSelect :options="categories" v-model="form.categories" placeholder="📁 Category" @update:modelValue="filter"/>
                            </div>
                            <div>
                                <MultiSelect :options="compensation" v-model="form.compensation" placeholder="💵 Comp" @update:modelValue="filter"/>
                            </div>
                            <div>
                                <MultiSelect :options="skills" v-model="form.skills" placeholder="💻 Skills" @update:modelValue="filter"/>
                            </div>
                        </div>
                    </DisclosurePanel>
                </transition>
            </Disclosure>
            <div class="hidden sm:grid grid-cols-2 sm:grid-cols-4 mb-2 gap-x-1.5 gap-y-2 px-6">
                <div>
                    <MultiSelect :options="types" v-model="form.types" placeholder="🔍 Type" @update:modelValue="filter"/>
                </div>
                <div>
                    <MultiSelect :options="categories" v-model="form.categories" placeholder="📁 Category" @update:modelValue="filter"/>
                </div>
                <div>
                    <MultiSelect :options="compensation" v-model="form.compensation" placeholder="💵 Comp" @update:modelValue="filter"/>
                </div>
                <div>
                    <MultiSelect :options="skills" v-model="form.skills" placeholder="💻 Skills" @update:modelValue="filter"/>
                </div>
            </div>
            <div class="flex gap-x-2 flex-wrap gap-y-2 justify-between px-6">
                <div class="flex flex-wrap gap-x-2 gap-y-2 w-full">
                    <Badge v-for="type in mappedTags.types" color="blue" closable @close="() => removeTag('types', type)">
                        {{ type.name.toLowerCase() }}
                    </Badge>
                    <Badge v-for="category in mappedTags.categories" color="purple" closable @close="() => removeTag('categories', category)">
                        {{ category.name.toLowerCase() }}
                    </Badge>
                    <Badge v-for="compensation in mappedTags.compensation" color="green" closable @close="() => removeTag('compensation', compensation)">
                        {{ compensation.name.toLowerCase() }}
                    </Badge>
                    <Badge v-for="skill in mappedTags.skills" color="pink" closable @close="() => removeTag('skills', skill)">
                        {{ skill.name.toLowerCase() }}
                    </Badge>
                    <Badge
                        v-if="hasTags()"
                        class="hover:bg-gray-500/20 cursor-pointer group"
                        color="gray"
                        @click="clear"
                    >
                        <span class="relative -ml-1 h-3.5 w-3.5 rounded-sm">
                            <span class="sr-only">Remove</span>
                            <svg viewBox="0 0 14 14" class="h-3.5 w-3.5 stroke-gray-600/50 group-hover:stroke-gray-600/75">
                                <path d="M4 4l6 6m0-6l-6 6" />
                            </svg>
                            <span class="absolute -inset-1" />
                        </span> Clear All
                    </Badge>
                </div>
            </div>
        </div>
        <div>
            <div class="flex flex-col gap-y-4">
                <div class="flex flex-col divide-y divide-gray-200 border-b border-gray-200">
                        <Listing
                            class="px-6 py-5 sm:py-6"
                            v-for="listing in listings.data"
                            :key="`listing_${listing.id}`"
                            :listing="listing"
                            excerpt
                        />
                </div>
                <div class="px-6">
                    <Pagination :next-page-url="listings.next_page_url" :previous-page-url="listings.prev_page_url" />
                </div>
            </div>
        </div>
    </App>
</template>

<script setup>
import Badge from "@/Components/Badge.vue";
import {useForm} from "@inertiajs/vue3";
import Pagination from "@/Components/Pagination.vue";
import AppHead from "@/Components/AppHead.vue";
import App from "@/Layouts/App.vue";
import {computed} from "vue";
import MultiSelect from "@/Components/MultiSelect.vue";
import Listing from "@/Components/Listing.vue";
import Heading from "@/Components/Heading.vue";
import {Disclosure, DisclosureButton, DisclosurePanel} from "@headlessui/vue";
import {ChevronDownIcon} from "@heroicons/vue/24/outline/index.js";
import Logo from "@/Components/Logo.vue";

const props = defineProps({
    is_beta: Boolean,
    listings: {
        type: Object,
        required: true,
    },
    types: {
        type: Array,
        required: false,
        default: [],
    },
    categories: {
        type: Array,
        required: false,
        default: [],
    },
    skills: {
        type: Array,
        required: false,
        default: [],
    },
    compensation: {
        type: Array,
        required: false,
        default: [],
    },
});

const params = route().params;

const form = useForm('filters', {
    types: props.types.filter((item) => params.types?.includes(item.id)).map((item) => item.id),
    categories: props.categories.filter((item) => params.categories?.includes(item.id)).map((item) => item.id),
    skills: props.skills.filter((item) => params.skills?.includes(item.id)).map((item) => item.id),
    compensation: props.compensation.filter((item) => params.compensation?.includes(item.id)).map((item) => item.id),
});

const mappedTags = computed(() => ({
    types: props.types.filter((item) => form.types.includes(item.id)),
    categories: props.categories.filter((item) => form.categories.includes(item.id)),
    skills: props.skills.filter((item) => form.skills.includes(item.id)),
    compensation: props.compensation.filter((item) => form.compensation.includes(item.id)),
}));

const hasTags = () => form.types.length || form.categories.length || form.skills.length || form.compensation.length;

const filter = () => {
    form
        .get(route('listing.index'), {
            preserveState: true,
            preserveScroll: true,
            only: ['listings'],
        });
};

const removeTag = (type, tag) => {
    form[type] = form[type].filter(id => id !== tag.id);

    filter();
};

const clear = () => {
    form.types = [];
    form.categories = [];
    form.skills = [];
    form.compensation = [];

  filter();
};

</script>
